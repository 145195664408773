import React, { useState, useEffect } from 'react';
import axios from 'axios'
import moment from 'moment'
import './App.css'
import { backendURL, allPills } from './constants'
import "antd/dist/antd.css"
import { Table, Button, Checkbox, Modal, message } from 'antd'
const { Column } = Table

const showSuccess = (added_item) => message.success(`Added ${added_item}!`)
const getCheckedPills = () => axios(backendURL + '/checked-pills')
const getDisabledPills = () => axios(backendURL + '/disabled-check-list')
const deleteDisabledChecked = () => axios.delete(backendURL + '/disabled-check-list')
const postDisabled = (newlyDisabled) => axios.post(backendURL + '/disabled-check-list', newlyDisabled)
const deleteChecked = () => axios.delete(backendURL + '/checked-pills')
const postHistory = (pillsTaken, missed=false) => axios.post(backendURL + '/pill-history' + (missed ? '?missed=true' : '?missed=false'), pillsTaken)
// const getAllPills = () => axios(backendURL + '/pills')
const postPills = () => axios.post(backendURL + '/pills', allPills)

const importAll = (r) => {
  let images = {}
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = r(item)
    return r(item)
  })
  return images
}

const images = importAll(require.context('./images', false, /\.png$/));

const PillChart = (props) => {

  const currentTime = moment()
  const currentDay = currentTime.format('dddd')
  let currentHour = currentTime.hour()

  if ((currentHour <= 23) && (currentTime.hour() > 11)){
    currentHour = 19
  } else{
    currentHour = 9
  }

  const [pills, setPills] = useState([])
  const [checkedPills, setCheckedPills] = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [disabledPillNames, setDisabledPillNames] = useState([])
  const [showMissedPillsModal, setShowMissedPillsModal] = useState(false)
  const [lastHour, setLastHour] = useState(0)

  const allPillsTaken = (newlyCheckedPills) => {
    axios.all([postHistory(newlyCheckedPills), deleteChecked(), deleteDisabledChecked()])
      .then(() => {
        showSuccess("intake time for all medications")
        setCheckedPills([])
        setDisabledPillNames([])
      })
  }

  const missedPillsViewed = (missedPills) => {
    axios.all([postHistory(missedPills, true), deleteChecked(), deleteDisabledChecked()])
      .then(() => {
        showSuccess("missed pills")
        setCheckedPills([])
        setDisabledPillNames([])
      })
  }

  const pillsPartiallyTaken = (newlyCheckedPills) => {
    const fullDisabled = disabledPillNames.concat(newlyCheckedPills.map(({ name }) => name))
    axios.all([postHistory(newlyCheckedPills), postDisabled({
      "names": fullDisabled, "hourOfPost": currentHour
    })]).then(axios.spread((postPillRes, postDisabledRes) => {
        showSuccess("intake time for some medication")
        setDisabledPillNames(fullDisabled)
      }))
  }

  const handleMissedPills = (missedPills) => {
    setShowMissedPillsModal(false)
    setLastHour(0)
    missedPillsViewed(missedPills)
  }

  useEffect(() => {
    // can't have async as an argument to useEffect
    const fetchData = async () => {
      axios.all([getCheckedPills(), getDisabledPills()])
        .then(axios.spread((newCheckedPills, disabledPillNames) => {
          const { names, hourOfPost } = disabledPillNames.data
          if (names.length > 0){
            if(!hourOfPost){
              missedPillsViewed([])
            } else if(hourOfPost !== currentHour){
              setShowMissedPillsModal(true)
              setLastHour(hourOfPost)
            }
          }

          let pillData
          // WARNING: Now we always update the backend pills.
          // TODO: Come up with a better way that's more efficient than
          //        a deepcopy check against each pill and all pills
          // console.log(JSON.stringify(newPills) === JSON.stringify(allPills))
          // if(
          //   newPills.data.length > 0 &&
          //   newPills.data.length === allPills.length
          // ){
          //   pillData = newPills.data
          // } else{
          pillData = allPills
          postPills()
          // }
          console.log("this is pill data", pillData)
          setPills(pillData)
          setDisabledPillNames(names)
          setCheckedPills(newCheckedPills.data)
        }))
        .catch(() => {
          console.log("can't fetch checked or disabled pills")
          setPills(allPills)
        })
    }
    fetchData()
  }, [currentHour])

  const updateCheckedPills = (name, e) => {
    // console.log(e)
    if(e.target.checked){
      setCheckedPills(curr => [...curr, name])
    } else if(e.target.checked === false){
      setCheckedPills(curr => {
        const indexToRemove = curr.indexOf(name)
        const newArray = [...curr];
        newArray.splice(indexToRemove, 1);
        return newArray
      });
    }
  }


  const updateCheckedPillsDBRecords = (name, e) => {
    if(e.target.checked){
      checkedPills.push(name)
    } else{
      checkedPills.splice(checkedPills.indexOf(name), 1)
    }

    axios.post(backendURL + '/checked-pills', checkedPills)
      .then((response) => {
        setCheckedPills(response.data)
      })
  }

  const pillsToTakeNow = pills.map((pill) => {
    // Tell ma: CVC should be saran wrapped on shower

    /* TODO: Simulate
      - every other day (every 48 hours; remove the MWF),
        => maybe just store the prev day and check that moment.day - 1 or something else
      - 3 times a day
      - specific dates only
      - exlude certain dates

      ADD
      - excludedDates field in each pill, separated by ,
      - totalDosage to reflect the correct total amount he should get

      Finally
      - use postman to clear disabled and checked lists
    */
    let showPill = false
    switch(pill.days){
      case "everyday":
        showPill = true
        break
      case "M":
        showPill = currentDay[0] === "M"
        break
      case "F":
        showPill = currentDay[0] === "F"
        break
      case "Sa":
        showPill = !!currentDay.includes("Sa")
        break
      case "MWF":
        showPill = (currentDay[0] === "M" || currentDay[0] === "W" || currentDay[0] === "F")
        break
      case "SaMWF":
        showPill = (currentDay[0] === "M" || currentDay[0] === "W" || currentDay[0] === "F" || currentDay[0] === "Sa")
        break
      case "TuThSaSu":
        showPill = (currentDay.includes("Tu") || currentDay.includes("Th") || currentDay.includes("Sa") || currentDay.includes("Su"))
        break
      case "SuTuThSa":
        showPill = (currentDay.includes("Tu") || currentDay.includes("Th") || currentDay.includes("Sa") || currentDay.includes("Su"))
        break

      default:
        showPill = false
    }
    
    // don't show checked pills
    if(checkedPills.indexOf(pill.name) > -1){
      showPill = false
    }

    return (pill.hour.split(",").filter((hour) => +hour === currentHour).length && showPill) && pill
  }).filter((isHiddenPill) => !!isHiddenPill)


  const handleFinishPills = () => {
    const nonDisabledCheckedPills = pillsToTakeNow.filter(({ name }) => disabledPillNames.indexOf(name) === -1 && checkedPills.indexOf(name) > -1)

    if(checkedPills.length === pillsToTakeNow.length){
      allPillsTaken(nonDisabledCheckedPills)
    } else{
      pillsPartiallyTaken(nonDisabledCheckedPills)
    }
    setShowConfirmModal(false)
  }

  const missedPills = allPills.filter((pill) =>
    (disabledPillNames.indexOf(pill.name) === -1) && (pill.hour.indexOf(lastHour) > -1)
  )
  // console.log(checkedPills)
  return (
    <div className="mb5 center">
      <h1 className="flex-ns justify-center pv4">
        Hi Dad! It's {currentDay}. Take {`${pillsToTakeNow.reduce((total,pill) => total+pill.numPills, 0)}`} pills around {currentHour > 9 ? "7pm" : "9am"}.
      </h1>
      <Table
        dataSource={pillsToTakeNow}
        rowKey="name"
        scroll={{ x: 850 }}
        pagination={false}
      >
        <Column
          title=""
          dataIndex="img"
          width={100}
          align="center"
          render={(text, record) => (<img alt="nothing.png" src={images[text]} height="128" width="128"/>)}
        />
        <Column
          title="Name"
          dataIndex="name"
          width={20}
          align="center"
          className="b"
          render={(text, record) => text}
        />
        <Column
          title="Dosage"
          dataIndex="dosage"
          width={20}
          align="center"
          className="b"
          render={(text, record) => text}
        />
        <Column
          title="How much"
          dataIndex="numPills"
          width={20}
          align="center"
          className="b"
          render={(text, record) => record.numPills ? (`${record.numPills} ${record.numPills === 1 ? "pill" : "pills"}`) : record.amount}
        />
        <Column
          title="Comments"
          dataIndex="comments"
          width={100}
          align="center"
          render={(text, record) => text}
        />
        <Column
          width={20}
          align="center"
          render={(text, record) => (
            <Checkbox
              className="mt3"
              checked={(checkedPills.indexOf(record.name) > -1)}
              disabled={disabledPillNames.indexOf(record.name) > -1}
              onChange={(e) => updateCheckedPills(record.name, e)}
            >
              Collected
            </Checkbox>
          )}
        />
      </Table>
      <div className="flex-ns justify-center">
        <Button type="primary br3 pa4 mt4 mb4" onClick={() => setShowConfirmModal(true)}>Finished Taking Pills</Button>
      </div>
      <Modal
        centered={true}
        visible={showConfirmModal}
        onOk={handleFinishPills}
        okText="Yes"
        cancelText="No"
        onCancel={() => setShowConfirmModal(false)}
      >
        {(checkedPills.length !== pillsToTakeNow.length) ? (
          <div>
            <h2 className="b tc">Are you sure?</h2>
            <p className="red">The following medications were not taken:</p>
            <ul>
              { pillsToTakeNow.filter(({ name }) => checkedPills.indexOf(name) === -1).map(({ name }, i) =>
                <li key={i}>{name}</li>
              )}
            </ul>
          </div>
        ) : (
          <h2 className="tc">You've taken all your pills! Press 'Yes' to confirm submission.</h2>
        )}
      </Modal>
      <Modal
        visible={showMissedPillsModal}
        onOk={() => handleMissedPills(missedPills)}
        okText="Yes"
        cancelText="No"
      >
        <div>
          <h3 className="mv3 tc b"> Are you certain that you took these medications? </h3>
          <div className="flex-ns justify-between">
            <div className="w-50-ns">
              <h4 className="green">These are the pills you took last time </h4>
                <ul>
                  {disabledPillNames.map((name, i) => (
                    <li key={i}>{name}</li>
                  ))}
                </ul>
            </div>
            <div className="w-50-ns">
              <h4 className="red tc">These are the pills that weren't submitted last time.</h4>
                <ul>
                  { missedPills.map( ({ name }, i) => <li key={i}>{name}</li> ) }
                </ul>
            </div>
          </div>
        </div>
      </Modal>

    </div>
  )
}

export default PillChart
